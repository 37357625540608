import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';

const Plane = () => {
  const { scene } = useThree();
  const meshRef = useRef(null);
  const clock = useRef(new THREE.Clock());

  useEffect(() => {
    const geometry = new THREE.PlaneGeometry(256, 256);
    const material = new THREE.MeshBasicMaterial({
      color: 'lightblue',
      transparent: true,
      opacity: 0.6,
      wireframe: true, // Optional: enable wireframe for a basic outline
    });

    const mesh = new THREE.Mesh(geometry, material);
    meshRef.current = mesh;
    scene.add(mesh);
  }, []);

  useFrame(() => {
    const elapsedTime = clock.current.getElapsedTime();
    const rotation = elapsedTime * 0.5; // Adjust rotation speed as needed
    meshRef.current.rotation.x = rotation;
  });

  return null;
};

function BG() {
  return (
    <Canvas>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Plane />
    </Canvas>
  );
}

export default BG;