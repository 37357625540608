import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import BG from './background_hero'

const root = ReactDOM.createRoot(document.getElementById('lanyard'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
const simple_desc = ReactDOM.createRoot(document.querySelector(".simple_desc"))
const date_now = new Date()
const age_Count = date_now.getFullYear() - 2003
simple_desc.render(`${age_Count} year old tech enthusiasm 🖥️ based in Sidoarjo 🇮🇩 making videos, design graphics, programs and some illustration.`)

const background = ReactDOM.createRoot(document.querySelector(".anu"))
background.render(  <React.StrictMode>
  <BG />
</React.StrictMode>)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
